// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file uxstack/sdk/v1/sdk.proto (package uxstack.sdk.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from enum uxstack.sdk.v1.DeviceType
 */
export enum DeviceType {
  /**
   * @generated from enum value: DEVICE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DEVICE_TYPE_UNKNOWN = 1;
   */
  UNKNOWN = 1,

  /**
   * @generated from enum value: DEVICE_TYPE_DESKTOP = 2;
   */
  DESKTOP = 2,

  /**
   * @generated from enum value: DEVICE_TYPE_TABLET = 3;
   */
  TABLET = 3,

  /**
   * @generated from enum value: DEVICE_TYPE_MOBILE = 4;
   */
  MOBILE = 4,

  /**
   * @generated from enum value: DEVICE_TYPE_CONSOLE = 5;
   */
  CONSOLE = 5,

  /**
   * @generated from enum value: DEVICE_TYPE_WEARABLE = 6;
   */
  WEARABLE = 6,

  /**
   * @generated from enum value: DEVICE_TYPE_TV = 7;
   */
  TV = 7,

  /**
   * @generated from enum value: DEVICE_TYPE_AUTOMOTIVE = 8;
   */
  AUTOMOTIVE = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(DeviceType)
proto3.util.setEnumType(DeviceType, "uxstack.sdk.v1.DeviceType", [
  { no: 0, name: "DEVICE_TYPE_UNSPECIFIED" },
  { no: 1, name: "DEVICE_TYPE_UNKNOWN" },
  { no: 2, name: "DEVICE_TYPE_DESKTOP" },
  { no: 3, name: "DEVICE_TYPE_TABLET" },
  { no: 4, name: "DEVICE_TYPE_MOBILE" },
  { no: 5, name: "DEVICE_TYPE_CONSOLE" },
  { no: 6, name: "DEVICE_TYPE_WEARABLE" },
  { no: 7, name: "DEVICE_TYPE_TV" },
  { no: 8, name: "DEVICE_TYPE_AUTOMOTIVE" },
]);

/**
 * @generated from message uxstack.sdk.v1.EventBatch
 */
export class EventBatch extends Message<EventBatch> {
  /**
   * @generated from field: repeated uxstack.sdk.v1.EventMessage events = 1;
   */
  events: EventMessage[] = [];

  constructor(data?: PartialMessage<EventBatch>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventBatch";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "events", kind: "message", T: EventMessage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventBatch {
    return new EventBatch().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventBatch {
    return new EventBatch().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventBatch {
    return new EventBatch().fromJsonString(jsonString, options);
  }

  static equals(a: EventBatch | PlainMessage<EventBatch> | undefined, b: EventBatch | PlainMessage<EventBatch> | undefined): boolean {
    return proto3.util.equals(EventBatch, a, b);
  }
}

/**
 *
 * message Any {
 * string type_url = 1;
 * bytes value = 2;
 * }
 *
 * @generated from message uxstack.sdk.v1.EventMessage
 */
export class EventMessage extends Message<EventMessage> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string correlation_id = 2;
   */
  correlationId = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: string action = 4;
   */
  action = "";

  /**
   * @generated from field: uxstack.sdk.v1.EventContext context = 5;
   */
  context?: EventContext;

  /**
   * @generated from field: fixed64 created_at = 6;
   */
  createdAt = protoInt64.zero;

  /**
   * @generated from field: fixed64 sent_at = 7;
   */
  sentAt = protoInt64.zero;

  /**
   * @generated from field: fixed64 received_at = 8;
   */
  receivedAt = protoInt64.zero;

  /**
   * @generated from oneof uxstack.sdk.v1.EventMessage.payload
   */
  payload: {
    /**
     * @generated from field: uxstack.sdk.v1.PageViewPayload page_view = 9;
     */
    value: PageViewPayload;
    case: "pageView";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.PageDurationPayload page_duration = 10;
     */
    value: PageDurationPayload;
    case: "pageDuration";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.TrackPayload track = 11;
     */
    value: TrackPayload;
    case: "track";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.PerformancePayload performance = 12;
     */
    value: PerformancePayload;
    case: "performance";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.PageClickPayload page_click = 13;
     */
    value: PageClickPayload;
    case: "pageClick";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.VitalsPayload vitals = 14;
     */
    value: VitalsPayload;
    case: "vitals";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<EventMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventMessage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "correlation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "action", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "context", kind: "message", T: EventContext },
    { no: 6, name: "created_at", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
    { no: 7, name: "sent_at", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
    { no: 8, name: "received_at", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
    { no: 9, name: "page_view", kind: "message", T: PageViewPayload, oneof: "payload" },
    { no: 10, name: "page_duration", kind: "message", T: PageDurationPayload, oneof: "payload" },
    { no: 11, name: "track", kind: "message", T: TrackPayload, oneof: "payload" },
    { no: 12, name: "performance", kind: "message", T: PerformancePayload, oneof: "payload" },
    { no: 13, name: "page_click", kind: "message", T: PageClickPayload, oneof: "payload" },
    { no: 14, name: "vitals", kind: "message", T: VitalsPayload, oneof: "payload" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventMessage {
    return new EventMessage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventMessage {
    return new EventMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventMessage {
    return new EventMessage().fromJsonString(jsonString, options);
  }

  static equals(a: EventMessage | PlainMessage<EventMessage> | undefined, b: EventMessage | PlainMessage<EventMessage> | undefined): boolean {
    return proto3.util.equals(EventMessage, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.EventContext
 */
export class EventContext extends Message<EventContext> {
  /**
   * @generated from field: uxstack.sdk.v1.EventContextApp app = 1;
   */
  app?: EventContextApp;

  /**
   * @generated from field: uxstack.sdk.v1.EventContextUser user = 2;
   */
  user?: EventContextUser;

  /**
   * @generated from field: uxstack.sdk.v1.EventContextAgent agent = 3;
   */
  agent?: EventContextAgent;

  /**
   * @generated from field: uxstack.sdk.v1.EventContextSession session = 4;
   */
  session?: EventContextSession;

  /**
   * @generated from field: uxstack.sdk.v1.EventContextLibrary library = 5;
   */
  library?: EventContextLibrary;

  /**
   * @generated from field: uxstack.sdk.v1.EventContextLocation location = 6;
   */
  location?: EventContextLocation;

  /**
   * @generated from field: uxstack.sdk.v1.EventContextGeo geo = 7;
   */
  geo?: EventContextGeo;

  constructor(data?: PartialMessage<EventContext>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventContext";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "app", kind: "message", T: EventContextApp },
    { no: 2, name: "user", kind: "message", T: EventContextUser },
    { no: 3, name: "agent", kind: "message", T: EventContextAgent },
    { no: 4, name: "session", kind: "message", T: EventContextSession },
    { no: 5, name: "library", kind: "message", T: EventContextLibrary },
    { no: 6, name: "location", kind: "message", T: EventContextLocation },
    { no: 7, name: "geo", kind: "message", T: EventContextGeo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventContext {
    return new EventContext().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventContext {
    return new EventContext().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventContext {
    return new EventContext().fromJsonString(jsonString, options);
  }

  static equals(a: EventContext | PlainMessage<EventContext> | undefined, b: EventContext | PlainMessage<EventContext> | undefined): boolean {
    return proto3.util.equals(EventContext, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.EventContextApp
 */
export class EventContextApp extends Message<EventContextApp> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  constructor(data?: PartialMessage<EventContextApp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventContextApp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventContextApp {
    return new EventContextApp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventContextApp {
    return new EventContextApp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventContextApp {
    return new EventContextApp().fromJsonString(jsonString, options);
  }

  static equals(a: EventContextApp | PlainMessage<EventContextApp> | undefined, b: EventContextApp | PlainMessage<EventContextApp> | undefined): boolean {
    return proto3.util.equals(EventContextApp, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.EventContextUser
 */
export class EventContextUser extends Message<EventContextUser> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  /**
   * @generated from field: map<string, string> properties = 4;
   */
  properties: { [key: string]: string } = {};

  constructor(data?: PartialMessage<EventContextUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventContextUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "properties", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventContextUser {
    return new EventContextUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventContextUser {
    return new EventContextUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventContextUser {
    return new EventContextUser().fromJsonString(jsonString, options);
  }

  static equals(a: EventContextUser | PlainMessage<EventContextUser> | undefined, b: EventContextUser | PlainMessage<EventContextUser> | undefined): boolean {
    return proto3.util.equals(EventContextUser, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.Size
 */
export class Size extends Message<Size> {
  /**
   * @generated from field: fixed32 width = 1;
   */
  width = 0;

  /**
   * @generated from field: fixed32 height = 2;
   */
  height = 0;

  constructor(data?: PartialMessage<Size>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.Size";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "width", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
    { no: 2, name: "height", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Size {
    return new Size().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Size {
    return new Size().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Size {
    return new Size().fromJsonString(jsonString, options);
  }

  static equals(a: Size | PlainMessage<Size> | undefined, b: Size | PlainMessage<Size> | undefined): boolean {
    return proto3.util.equals(Size, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.OSInfo
 */
export class OSInfo extends Message<OSInfo> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  constructor(data?: PartialMessage<OSInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.OSInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OSInfo {
    return new OSInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OSInfo {
    return new OSInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OSInfo {
    return new OSInfo().fromJsonString(jsonString, options);
  }

  static equals(a: OSInfo | PlainMessage<OSInfo> | undefined, b: OSInfo | PlainMessage<OSInfo> | undefined): boolean {
    return proto3.util.equals(OSInfo, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.BrowserInfo
 */
export class BrowserInfo extends Message<BrowserInfo> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  constructor(data?: PartialMessage<BrowserInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.BrowserInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrowserInfo {
    return new BrowserInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrowserInfo {
    return new BrowserInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrowserInfo {
    return new BrowserInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BrowserInfo | PlainMessage<BrowserInfo> | undefined, b: BrowserInfo | PlainMessage<BrowserInfo> | undefined): boolean {
    return proto3.util.equals(BrowserInfo, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.EventContextAgent
 */
export class EventContextAgent extends Message<EventContextAgent> {
  /**
   * @generated from field: uxstack.sdk.v1.Size screen_size = 1;
   */
  screenSize?: Size;

  /**
   * @generated from field: uxstack.sdk.v1.Size viewport_size = 2;
   */
  viewportSize?: Size;

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: string platform = 4;
   */
  platform = "";

  /**
   * @generated from field: string user_agent = 5;
   */
  userAgent = "";

  /**
   * @generated from field: string language = 6;
   */
  language = "";

  /**
   * @generated from field: float pixel_ratio = 7;
   */
  pixelRatio = 0;

  /**
   * @generated from field: string vendor = 8;
   */
  vendor = "";

  /**
   * @generated from field: string encoding = 9;
   */
  encoding = "";

  /**
   * @generated from field: uxstack.sdk.v1.DeviceType device = 10;
   */
  device = DeviceType.UNSPECIFIED;

  /**
   * @generated from field: uxstack.sdk.v1.OSInfo os = 11;
   */
  os?: OSInfo;

  /**
   * @generated from field: uxstack.sdk.v1.BrowserInfo browser = 12;
   */
  browser?: BrowserInfo;

  /**
   * @generated from field: string orientation = 13;
   */
  orientation = "";

  /**
   * @generated from field: bool is_mobile = 14;
   */
  isMobile = false;

  constructor(data?: PartialMessage<EventContextAgent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventContextAgent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "screen_size", kind: "message", T: Size },
    { no: 2, name: "viewport_size", kind: "message", T: Size },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "platform", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "user_agent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "pixel_ratio", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "vendor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "encoding", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "device", kind: "enum", T: proto3.getEnumType(DeviceType) },
    { no: 11, name: "os", kind: "message", T: OSInfo },
    { no: 12, name: "browser", kind: "message", T: BrowserInfo },
    { no: 13, name: "orientation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "is_mobile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventContextAgent {
    return new EventContextAgent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventContextAgent {
    return new EventContextAgent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventContextAgent {
    return new EventContextAgent().fromJsonString(jsonString, options);
  }

  static equals(a: EventContextAgent | PlainMessage<EventContextAgent> | undefined, b: EventContextAgent | PlainMessage<EventContextAgent> | undefined): boolean {
    return proto3.util.equals(EventContextAgent, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.EventContextSession
 */
export class EventContextSession extends Message<EventContextSession> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string ip = 2;
   */
  ip = "";

  /**
   * @generated from field: string isp = 3;
   */
  isp = "";

  constructor(data?: PartialMessage<EventContextSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventContextSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "isp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventContextSession {
    return new EventContextSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventContextSession {
    return new EventContextSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventContextSession {
    return new EventContextSession().fromJsonString(jsonString, options);
  }

  static equals(a: EventContextSession | PlainMessage<EventContextSession> | undefined, b: EventContextSession | PlainMessage<EventContextSession> | undefined): boolean {
    return proto3.util.equals(EventContextSession, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.EventContextLibrary
 */
export class EventContextLibrary extends Message<EventContextLibrary> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  constructor(data?: PartialMessage<EventContextLibrary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventContextLibrary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventContextLibrary {
    return new EventContextLibrary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventContextLibrary {
    return new EventContextLibrary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventContextLibrary {
    return new EventContextLibrary().fromJsonString(jsonString, options);
  }

  static equals(a: EventContextLibrary | PlainMessage<EventContextLibrary> | undefined, b: EventContextLibrary | PlainMessage<EventContextLibrary> | undefined): boolean {
    return proto3.util.equals(EventContextLibrary, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.EventContextLocation
 */
export class EventContextLocation extends Message<EventContextLocation> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string referrer = 3;
   */
  referrer = "";

  constructor(data?: PartialMessage<EventContextLocation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventContextLocation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "referrer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventContextLocation {
    return new EventContextLocation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventContextLocation {
    return new EventContextLocation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventContextLocation {
    return new EventContextLocation().fromJsonString(jsonString, options);
  }

  static equals(a: EventContextLocation | PlainMessage<EventContextLocation> | undefined, b: EventContextLocation | PlainMessage<EventContextLocation> | undefined): boolean {
    return proto3.util.equals(EventContextLocation, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.EventContextGeo
 */
export class EventContextGeo extends Message<EventContextGeo> {
  /**
   * @generated from field: uint64 city_id = 1;
   */
  cityId = protoInt64.zero;

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  /**
   * @generated from field: string time_zone = 3;
   */
  timeZone = "";

  constructor(data?: PartialMessage<EventContextGeo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.EventContextGeo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "city_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "time_zone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EventContextGeo {
    return new EventContextGeo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EventContextGeo {
    return new EventContextGeo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EventContextGeo {
    return new EventContextGeo().fromJsonString(jsonString, options);
  }

  static equals(a: EventContextGeo | PlainMessage<EventContextGeo> | undefined, b: EventContextGeo | PlainMessage<EventContextGeo> | undefined): boolean {
    return proto3.util.equals(EventContextGeo, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.VitalsPayload
 */
export class VitalsPayload extends Message<VitalsPayload> {
  /**
   * @generated from field: repeated uxstack.sdk.v1.Vital vitals = 1;
   */
  vitals: Vital[] = [];

  constructor(data?: PartialMessage<VitalsPayload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.VitalsPayload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vitals", kind: "message", T: Vital, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VitalsPayload {
    return new VitalsPayload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VitalsPayload {
    return new VitalsPayload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VitalsPayload {
    return new VitalsPayload().fromJsonString(jsonString, options);
  }

  static equals(a: VitalsPayload | PlainMessage<VitalsPayload> | undefined, b: VitalsPayload | PlainMessage<VitalsPayload> | undefined): boolean {
    return proto3.util.equals(VitalsPayload, a, b);
  }
}

/**
 *
 * message VitalEntry {
 * string selector = 1;
 * double renderTime = 2;
 * double loadTime = 3;
 * double size = 4;
 * string id = 5;
 * double duration = 6;
 * double startTime = 7;
 * string entryType = 8;
 * string url = 9;
 * string name = 10;
 * bool cancelable = 11;
 * fixed32 interactionId = 12;
 * double processingStart = 13;
 * double processingEnd = 14;
 * }
 *
 * @generated from message uxstack.sdk.v1.Vital
 */
export class Vital extends Message<Vital> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: double value = 2;
   */
  value = 0;

  /**
   * @generated from field: double delta = 3;
   */
  delta = 0;

  /**
   * @generated from field: string id = 4;
   */
  id = "";

  /**
   * @generated from field: string navigation_type = 5;
   */
  navigationType = "";

  /**
   * repeated VitalEntry entries = 6;
   *
   * @generated from field: string rating = 6;
   */
  rating = "";

  constructor(data?: PartialMessage<Vital>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.Vital";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "delta", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "navigation_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "rating", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Vital {
    return new Vital().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Vital {
    return new Vital().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Vital {
    return new Vital().fromJsonString(jsonString, options);
  }

  static equals(a: Vital | PlainMessage<Vital> | undefined, b: Vital | PlainMessage<Vital> | undefined): boolean {
    return proto3.util.equals(Vital, a, b);
  }
}

/**
 * Page View Event Payload
 *
 * @generated from message uxstack.sdk.v1.PageViewPayload
 */
export class PageViewPayload extends Message<PageViewPayload> {
  /**
   * @generated from field: fixed64 start_at = 1;
   */
  startAt = protoInt64.zero;

  /**
   * @generated from field: fixed64 end_at = 2;
   */
  endAt = protoInt64.zero;

  constructor(data?: PartialMessage<PageViewPayload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PageViewPayload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_at", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
    { no: 2, name: "end_at", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PageViewPayload {
    return new PageViewPayload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PageViewPayload {
    return new PageViewPayload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PageViewPayload {
    return new PageViewPayload().fromJsonString(jsonString, options);
  }

  static equals(a: PageViewPayload | PlainMessage<PageViewPayload> | undefined, b: PageViewPayload | PlainMessage<PageViewPayload> | undefined): boolean {
    return proto3.util.equals(PageViewPayload, a, b);
  }
}

/**
 * Page Duration Event Payload
 *
 * @generated from message uxstack.sdk.v1.PageDurationPayload
 */
export class PageDurationPayload extends Message<PageDurationPayload> {
  /**
   * @generated from field: fixed64 start_at = 1;
   */
  startAt = protoInt64.zero;

  /**
   * @generated from field: fixed64 end_at = 2;
   */
  endAt = protoInt64.zero;

  constructor(data?: PartialMessage<PageDurationPayload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PageDurationPayload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_at", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
    { no: 2, name: "end_at", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PageDurationPayload {
    return new PageDurationPayload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PageDurationPayload {
    return new PageDurationPayload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PageDurationPayload {
    return new PageDurationPayload().fromJsonString(jsonString, options);
  }

  static equals(a: PageDurationPayload | PlainMessage<PageDurationPayload> | undefined, b: PageDurationPayload | PlainMessage<PageDurationPayload> | undefined): boolean {
    return proto3.util.equals(PageDurationPayload, a, b);
  }
}

/**
 * Track Event Payload
 *
 * @generated from message uxstack.sdk.v1.TrackPayload
 */
export class TrackPayload extends Message<TrackPayload> {
  /**
   * @generated from field: map<string, string> properties = 1;
   */
  properties: { [key: string]: string } = {};

  constructor(data?: PartialMessage<TrackPayload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.TrackPayload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "properties", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackPayload {
    return new TrackPayload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackPayload {
    return new TrackPayload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackPayload {
    return new TrackPayload().fromJsonString(jsonString, options);
  }

  static equals(a: TrackPayload | PlainMessage<TrackPayload> | undefined, b: TrackPayload | PlainMessage<TrackPayload> | undefined): boolean {
    return proto3.util.equals(TrackPayload, a, b);
  }
}

/**
 * Performance Event Payload
 *
 * @generated from message uxstack.sdk.v1.PerformancePayload
 */
export class PerformancePayload extends Message<PerformancePayload> {
  /**
   * @generated from field: repeated uxstack.sdk.v1.PerformanceEntry entries = 1;
   */
  entries: PerformanceEntry[] = [];

  constructor(data?: PartialMessage<PerformancePayload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PerformancePayload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entries", kind: "message", T: PerformanceEntry, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformancePayload {
    return new PerformancePayload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformancePayload {
    return new PerformancePayload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformancePayload {
    return new PerformancePayload().fromJsonString(jsonString, options);
  }

  static equals(a: PerformancePayload | PlainMessage<PerformancePayload> | undefined, b: PerformancePayload | PlainMessage<PerformancePayload> | undefined): boolean {
    return proto3.util.equals(PerformancePayload, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.PageClickPayload
 */
export class PageClickPayload extends Message<PageClickPayload> {
  /**
   * @generated from field: uxstack.sdk.v1.Coordinates coordinates = 1;
   */
  coordinates?: Coordinates;

  /**
   * @generated from field: string selector = 2;
   */
  selector = "";

  /**
   * @generated from field: string text = 3;
   */
  text = "";

  constructor(data?: PartialMessage<PageClickPayload>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PageClickPayload";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "coordinates", kind: "message", T: Coordinates },
    { no: 2, name: "selector", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PageClickPayload {
    return new PageClickPayload().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PageClickPayload {
    return new PageClickPayload().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PageClickPayload {
    return new PageClickPayload().fromJsonString(jsonString, options);
  }

  static equals(a: PageClickPayload | PlainMessage<PageClickPayload> | undefined, b: PageClickPayload | PlainMessage<PageClickPayload> | undefined): boolean {
    return proto3.util.equals(PageClickPayload, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.Coordinates
 */
export class Coordinates extends Message<Coordinates> {
  /**
   * @generated from field: uxstack.sdk.v1.Coordinate client = 1;
   */
  client?: Coordinate;

  /**
   * @generated from field: uxstack.sdk.v1.Coordinate offset = 2;
   */
  offset?: Coordinate;

  /**
   * @generated from field: uxstack.sdk.v1.Coordinate page = 3;
   */
  page?: Coordinate;

  /**
   * @generated from field: uxstack.sdk.v1.Coordinate scroll = 4;
   */
  scroll?: Coordinate;

  constructor(data?: PartialMessage<Coordinates>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.Coordinates";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client", kind: "message", T: Coordinate },
    { no: 2, name: "offset", kind: "message", T: Coordinate },
    { no: 3, name: "page", kind: "message", T: Coordinate },
    { no: 4, name: "scroll", kind: "message", T: Coordinate },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Coordinates {
    return new Coordinates().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Coordinates {
    return new Coordinates().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Coordinates {
    return new Coordinates().fromJsonString(jsonString, options);
  }

  static equals(a: Coordinates | PlainMessage<Coordinates> | undefined, b: Coordinates | PlainMessage<Coordinates> | undefined): boolean {
    return proto3.util.equals(Coordinates, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.Coordinate
 */
export class Coordinate extends Message<Coordinate> {
  /**
   * @generated from field: double x = 1;
   */
  x = 0;

  /**
   * @generated from field: double y = 2;
   */
  y = 0;

  constructor(data?: PartialMessage<Coordinate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.Coordinate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "x", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Coordinate {
    return new Coordinate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Coordinate {
    return new Coordinate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Coordinate {
    return new Coordinate().fromJsonString(jsonString, options);
  }

  static equals(a: Coordinate | PlainMessage<Coordinate> | undefined, b: Coordinate | PlainMessage<Coordinate> | undefined): boolean {
    return proto3.util.equals(Coordinate, a, b);
  }
}

/**
 *
 * {
 * "name": "https://www.folkstalk.com/wp-content/cache/autoptimize/js/autoptimize_5ee194b46b419fe4983e355f95719ba2.js",
 * "entryType": "resource",
 * "startTime": 3305.7000002861023,
 * "duration": 61.5,
 * "initiatorType": "script",
 * "nextHopProtocol": "h3",
 * "workerStart": 0,
 * "redirectStart": 0,
 * "redirectEnd": 0,
 * "fetchStart": 3305.7000002861023,
 * "domainLookupStart": 3305.7000002861023,
 * "domainLookupEnd": 3305.7000002861023,
 * "connectStart": 3305.7000002861023,
 * "connectEnd": 3305.7000002861023,
 * "secureConnectionStart": 3305.7000002861023,
 * "requestStart": 3329.300000190735,
 * "responseStart": 3360.4000000953674,
 * "responseEnd": 3367.2000002861023,
 * "transferSize": 3716,
 * "encodedBodySize": 3416,
 * "decodedBodySize": 12754,
 * "serverTiming": [],
 * "workerTiming": []
 * }
 *
 * @generated from message uxstack.sdk.v1.PerformanceResourceTiming
 */
export class PerformanceResourceTiming extends Message<PerformanceResourceTiming> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string entry_type = 2;
   */
  entryType = "";

  /**
   * @generated from field: double start_time = 3;
   */
  startTime = 0;

  /**
   * @generated from field: double duration = 4;
   */
  duration = 0;

  /**
   * @generated from field: string initiator_type = 5;
   */
  initiatorType = "";

  /**
   * @generated from field: string next_hop_protocol = 6;
   */
  nextHopProtocol = "";

  /**
   * @generated from field: double worker_start = 7;
   */
  workerStart = 0;

  /**
   * @generated from field: double redirect_start = 8;
   */
  redirectStart = 0;

  /**
   * @generated from field: double redirect_end = 9;
   */
  redirectEnd = 0;

  /**
   * @generated from field: double fetch_start = 10;
   */
  fetchStart = 0;

  /**
   * @generated from field: double domain_lookup_start = 11;
   */
  domainLookupStart = 0;

  /**
   * @generated from field: double domain_lookup_end = 12;
   */
  domainLookupEnd = 0;

  /**
   * @generated from field: double connect_start = 13;
   */
  connectStart = 0;

  /**
   * @generated from field: double connect_end = 14;
   */
  connectEnd = 0;

  /**
   * @generated from field: double secure_connection_start = 15;
   */
  secureConnectionStart = 0;

  /**
   * @generated from field: double request_start = 16;
   */
  requestStart = 0;

  /**
   * @generated from field: double response_start = 17;
   */
  responseStart = 0;

  /**
   * @generated from field: double response_end = 18;
   */
  responseEnd = 0;

  /**
   * @generated from field: fixed32 transfer_size = 19;
   */
  transferSize = 0;

  /**
   * @generated from field: fixed32 encoded_body_size = 20;
   */
  encodedBodySize = 0;

  /**
   * @generated from field: fixed32 decoded_body_size = 21;
   */
  decodedBodySize = 0;

  /**
   * @generated from field: repeated uxstack.sdk.v1.PerformanceServerTiming server_timing = 22;
   */
  serverTiming: PerformanceServerTiming[] = [];

  constructor(data?: PartialMessage<PerformanceResourceTiming>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PerformanceResourceTiming";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "initiator_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "next_hop_protocol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "worker_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "redirect_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "redirect_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "fetch_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "domain_lookup_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "domain_lookup_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "connect_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 14, name: "connect_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 15, name: "secure_connection_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "request_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 17, name: "response_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 18, name: "response_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 19, name: "transfer_size", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
    { no: 20, name: "encoded_body_size", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
    { no: 21, name: "decoded_body_size", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
    { no: 22, name: "server_timing", kind: "message", T: PerformanceServerTiming, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceResourceTiming {
    return new PerformanceResourceTiming().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceResourceTiming {
    return new PerformanceResourceTiming().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceResourceTiming {
    return new PerformanceResourceTiming().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceResourceTiming | PlainMessage<PerformanceResourceTiming> | undefined, b: PerformanceResourceTiming | PlainMessage<PerformanceResourceTiming> | undefined): boolean {
    return proto3.util.equals(PerformanceResourceTiming, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.PerformanceServerTiming
 */
export class PerformanceServerTiming extends Message<PerformanceServerTiming> {
  /**
   * @generated from field: string description = 1;
   */
  description = "";

  /**
   * @generated from field: double duration = 2;
   */
  duration = 0;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<PerformanceServerTiming>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PerformanceServerTiming";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceServerTiming {
    return new PerformanceServerTiming().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceServerTiming {
    return new PerformanceServerTiming().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceServerTiming {
    return new PerformanceServerTiming().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceServerTiming | PlainMessage<PerformanceServerTiming> | undefined, b: PerformanceServerTiming | PlainMessage<PerformanceServerTiming> | undefined): boolean {
    return proto3.util.equals(PerformanceServerTiming, a, b);
  }
}

/**
 *
 * {
 * "name": "https://www.folkstalk.com/2022/09/get-user-country-code-javascript-with-code-examples.html",
 * "entryType": "navigation",
 * "startTime": 0,
 * "duration": 5790.700000286102,
 * "initiatorType": "navigation",
 * "nextHopProtocol": "h2",
 * "workerStart": 0,
 * "redirectStart": 0,
 * "redirectEnd": 0,
 * "fetchStart": 11.800000190734863,
 * "domainLookupStart": 39.700000286102295,
 * "domainLookupEnd": 55.200000286102295,
 * "connectStart": 55.200000286102295,
 * "connectEnd": 116.7000002861023,
 * "secureConnectionStart": 73.40000009536743,
 * "requestStart": 117.10000038146973,
 * "responseStart": 1559.2000002861023,
 * "responseEnd": 1728.6000003814697,
 * "transferSize": 55414,
 * "encodedBodySize": 55114,
 * "decodedBodySize": 249554,
 * "serverTiming": [],
 * "workerTiming": [],
 * "unloadEventStart": 0,
 * "unloadEventEnd": 0,
 * "domInteractive": 3306.4000000953674,
 * "domContentLoadedEventStart": 3429,
 * "domContentLoadedEventEnd": 3448.4000000953674,
 * "domComplete": 5789.300000190735,
 * "loadEventStart": 5789.300000190735,
 * "loadEventEnd": 5790.700000286102,
 * "type": "navigate",
 * "redirectCount": 0
 * }
 *
 * @generated from message uxstack.sdk.v1.PerformanceNavigationTiming
 */
export class PerformanceNavigationTiming extends Message<PerformanceNavigationTiming> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string entry_type = 2;
   */
  entryType = "";

  /**
   * @generated from field: double start_time = 3;
   */
  startTime = 0;

  /**
   * @generated from field: double duration = 4;
   */
  duration = 0;

  /**
   * @generated from field: string initiator_type = 5;
   */
  initiatorType = "";

  /**
   * @generated from field: string next_hop_protocol = 6;
   */
  nextHopProtocol = "";

  /**
   * @generated from field: double worker_start = 7;
   */
  workerStart = 0;

  /**
   * @generated from field: double redirect_start = 8;
   */
  redirectStart = 0;

  /**
   * @generated from field: double redirect_end = 9;
   */
  redirectEnd = 0;

  /**
   * @generated from field: double fetch_start = 10;
   */
  fetchStart = 0;

  /**
   * @generated from field: double domain_lookup_start = 11;
   */
  domainLookupStart = 0;

  /**
   * @generated from field: double domain_lookup_end = 12;
   */
  domainLookupEnd = 0;

  /**
   * @generated from field: double connect_start = 13;
   */
  connectStart = 0;

  /**
   * @generated from field: double connect_end = 14;
   */
  connectEnd = 0;

  /**
   * @generated from field: double secure_connection_start = 15;
   */
  secureConnectionStart = 0;

  /**
   * @generated from field: double request_start = 16;
   */
  requestStart = 0;

  /**
   * @generated from field: double response_start = 17;
   */
  responseStart = 0;

  /**
   * @generated from field: double response_end = 18;
   */
  responseEnd = 0;

  /**
   * @generated from field: fixed32 transfer_size = 19;
   */
  transferSize = 0;

  /**
   * @generated from field: fixed32 encoded_body_size = 20;
   */
  encodedBodySize = 0;

  /**
   * @generated from field: fixed32 decoded_body_size = 21;
   */
  decodedBodySize = 0;

  /**
   * @generated from field: repeated uxstack.sdk.v1.PerformanceServerTiming server_timing = 22;
   */
  serverTiming: PerformanceServerTiming[] = [];

  /**
   * @generated from field: double dom_complete = 23;
   */
  domComplete = 0;

  /**
   * @generated from field: double dom_content_loaded_event_end = 24;
   */
  domContentLoadedEventEnd = 0;

  /**
   * @generated from field: double dom_content_loaded_event_start = 25;
   */
  domContentLoadedEventStart = 0;

  /**
   * @generated from field: double dom_interactive = 26;
   */
  domInteractive = 0;

  /**
   * @generated from field: double load_event_end = 27;
   */
  loadEventEnd = 0;

  /**
   * @generated from field: double load_event_start = 28;
   */
  loadEventStart = 0;

  /**
   * @generated from field: fixed32 redirect_count = 29;
   */
  redirectCount = 0;

  /**
   * @generated from field: string type = 30;
   */
  type = "";

  /**
   * @generated from field: double unload_event_end = 31;
   */
  unloadEventEnd = 0;

  /**
   * @generated from field: double unload_event_start = 32;
   */
  unloadEventStart = 0;

  constructor(data?: PartialMessage<PerformanceNavigationTiming>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PerformanceNavigationTiming";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "initiator_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "next_hop_protocol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "worker_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "redirect_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "redirect_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "fetch_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "domain_lookup_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "domain_lookup_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "connect_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 14, name: "connect_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 15, name: "secure_connection_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "request_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 17, name: "response_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 18, name: "response_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 19, name: "transfer_size", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
    { no: 20, name: "encoded_body_size", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
    { no: 21, name: "decoded_body_size", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
    { no: 22, name: "server_timing", kind: "message", T: PerformanceServerTiming, repeated: true },
    { no: 23, name: "dom_complete", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 24, name: "dom_content_loaded_event_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 25, name: "dom_content_loaded_event_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 26, name: "dom_interactive", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 27, name: "load_event_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 28, name: "load_event_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 29, name: "redirect_count", kind: "scalar", T: 7 /* ScalarType.FIXED32 */ },
    { no: 30, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "unload_event_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 32, name: "unload_event_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceNavigationTiming {
    return new PerformanceNavigationTiming().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceNavigationTiming {
    return new PerformanceNavigationTiming().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceNavigationTiming {
    return new PerformanceNavigationTiming().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceNavigationTiming | PlainMessage<PerformanceNavigationTiming> | undefined, b: PerformanceNavigationTiming | PlainMessage<PerformanceNavigationTiming> | undefined): boolean {
    return proto3.util.equals(PerformanceNavigationTiming, a, b);
  }
}

/**
 *
 * {
 * "name": "first-paint",
 * "entryType": "paint",
 * "startTime": 2966.2000002861023,
 * "duration": 0
 * }
 *
 * @generated from message uxstack.sdk.v1.PerformancePaintTiming
 */
export class PerformancePaintTiming extends Message<PerformancePaintTiming> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string entry_type = 2;
   */
  entryType = "";

  /**
   * @generated from field: double start_time = 3;
   */
  startTime = 0;

  /**
   * @generated from field: double duration = 4;
   */
  duration = 0;

  constructor(data?: PartialMessage<PerformancePaintTiming>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PerformancePaintTiming";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformancePaintTiming {
    return new PerformancePaintTiming().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformancePaintTiming {
    return new PerformancePaintTiming().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformancePaintTiming {
    return new PerformancePaintTiming().fromJsonString(jsonString, options);
  }

  static equals(a: PerformancePaintTiming | PlainMessage<PerformancePaintTiming> | undefined, b: PerformancePaintTiming | PlainMessage<PerformancePaintTiming> | undefined): boolean {
    return proto3.util.equals(PerformancePaintTiming, a, b);
  }
}

/**
 *
 * {
 * "name": "pointerdown",
 * "entryType": "first-input",
 * "startTime": 79078.7000002861,
 * "duration": 24,
 * "processingStart": 79091.90000009537,
 * "processingEnd": 79091.90000009537,
 * "cancelable": true
 * }
 *
 * @generated from message uxstack.sdk.v1.PerformanceEventTiming
 */
export class PerformanceEventTiming extends Message<PerformanceEventTiming> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string entry_type = 2;
   */
  entryType = "";

  /**
   * @generated from field: double start_time = 3;
   */
  startTime = 0;

  /**
   * @generated from field: double duration = 4;
   */
  duration = 0;

  /**
   * @generated from field: double processing_start = 5;
   */
  processingStart = 0;

  /**
   * @generated from field: double processing_end = 6;
   */
  processingEnd = 0;

  /**
   * @generated from field: bool cancelable = 7;
   */
  cancelable = false;

  /**
   * @generated from field: string target = 8;
   */
  target = "";

  constructor(data?: PartialMessage<PerformanceEventTiming>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PerformanceEventTiming";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "processing_start", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "processing_end", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "cancelable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "target", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceEventTiming {
    return new PerformanceEventTiming().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceEventTiming {
    return new PerformanceEventTiming().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceEventTiming {
    return new PerformanceEventTiming().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceEventTiming | PlainMessage<PerformanceEventTiming> | undefined, b: PerformanceEventTiming | PlainMessage<PerformanceEventTiming> | undefined): boolean {
    return proto3.util.equals(PerformanceEventTiming, a, b);
  }
}

/**
 *
 * {
 * "name": "foo",
 * "entryType": "measure",
 * "startTime": 0,
 * "duration": 4260695.200000286
 * }
 *
 * @generated from message uxstack.sdk.v1.PerformanceMeasure
 */
export class PerformanceMeasure extends Message<PerformanceMeasure> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string entry_type = 2;
   */
  entryType = "";

  /**
   * @generated from field: double start_time = 3;
   */
  startTime = 0;

  /**
   * @generated from field: double duration = 4;
   */
  duration = 0;

  constructor(data?: PartialMessage<PerformanceMeasure>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PerformanceMeasure";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceMeasure {
    return new PerformanceMeasure().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceMeasure {
    return new PerformanceMeasure().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceMeasure {
    return new PerformanceMeasure().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceMeasure | PlainMessage<PerformanceMeasure> | undefined, b: PerformanceMeasure | PlainMessage<PerformanceMeasure> | undefined): boolean {
    return proto3.util.equals(PerformanceMeasure, a, b);
  }
}

/**
 *
 * {
 * "name": "foo-mark",
 * "entryType": "mark",
 * "startTime": 4553624.700000286,
 * "duration": 0
 * }
 *
 * @generated from message uxstack.sdk.v1.PerformanceMark
 */
export class PerformanceMark extends Message<PerformanceMark> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string entry_type = 2;
   */
  entryType = "";

  /**
   * @generated from field: double start_time = 3;
   */
  startTime = 0;

  /**
   * @generated from field: double duration = 4;
   */
  duration = 0;

  constructor(data?: PartialMessage<PerformanceMark>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PerformanceMark";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceMark {
    return new PerformanceMark().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceMark {
    return new PerformanceMark().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceMark {
    return new PerformanceMark().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceMark | PlainMessage<PerformanceMark> | undefined, b: PerformanceMark | PlainMessage<PerformanceMark> | undefined): boolean {
    return proto3.util.equals(PerformanceMark, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.VisibilityState
 */
export class VisibilityState extends Message<VisibilityState> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string entry_type = 2;
   */
  entryType = "";

  /**
   * @generated from field: double start_time = 3;
   */
  startTime = 0;

  /**
   * @generated from field: double duration = 4;
   */
  duration = 0;

  constructor(data?: PartialMessage<VisibilityState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.VisibilityState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VisibilityState {
    return new VisibilityState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VisibilityState {
    return new VisibilityState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VisibilityState {
    return new VisibilityState().fromJsonString(jsonString, options);
  }

  static equals(a: VisibilityState | PlainMessage<VisibilityState> | undefined, b: VisibilityState | PlainMessage<VisibilityState> | undefined): boolean {
    return proto3.util.equals(VisibilityState, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.PerformanceEntry
 */
export class PerformanceEntry extends Message<PerformanceEntry> {
  /**
   * @generated from oneof uxstack.sdk.v1.PerformanceEntry.entry
   */
  entry: {
    /**
     * @generated from field: uxstack.sdk.v1.PerformanceNavigationTiming navigation_timing = 1;
     */
    value: PerformanceNavigationTiming;
    case: "navigationTiming";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.PerformanceResourceTiming resource_timing = 2;
     */
    value: PerformanceResourceTiming;
    case: "resourceTiming";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.PerformancePaintTiming paint_timing = 3;
     */
    value: PerformancePaintTiming;
    case: "paintTiming";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.PerformanceEventTiming event_timing = 4;
     */
    value: PerformanceEventTiming;
    case: "eventTiming";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.PerformanceMark mark = 5;
     */
    value: PerformanceMark;
    case: "mark";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.PerformanceMeasure measure = 6;
     */
    value: PerformanceMeasure;
    case: "measure";
  } | {
    /**
     * @generated from field: uxstack.sdk.v1.VisibilityState visibility_state = 7;
     */
    value: VisibilityState;
    case: "visibilityState";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<PerformanceEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.PerformanceEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "navigation_timing", kind: "message", T: PerformanceNavigationTiming, oneof: "entry" },
    { no: 2, name: "resource_timing", kind: "message", T: PerformanceResourceTiming, oneof: "entry" },
    { no: 3, name: "paint_timing", kind: "message", T: PerformancePaintTiming, oneof: "entry" },
    { no: 4, name: "event_timing", kind: "message", T: PerformanceEventTiming, oneof: "entry" },
    { no: 5, name: "mark", kind: "message", T: PerformanceMark, oneof: "entry" },
    { no: 6, name: "measure", kind: "message", T: PerformanceMeasure, oneof: "entry" },
    { no: 7, name: "visibility_state", kind: "message", T: VisibilityState, oneof: "entry" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceEntry {
    return new PerformanceEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceEntry {
    return new PerformanceEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceEntry {
    return new PerformanceEntry().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceEntry | PlainMessage<PerformanceEntry> | undefined, b: PerformanceEntry | PlainMessage<PerformanceEntry> | undefined): boolean {
    return proto3.util.equals(PerformanceEntry, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.TrackRequest
 */
export class TrackRequest extends Message<TrackRequest> {
  /**
   * @generated from field: repeated uxstack.sdk.v1.EventMessage events = 1;
   */
  events: EventMessage[] = [];

  constructor(data?: PartialMessage<TrackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.TrackRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "events", kind: "message", T: EventMessage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackRequest {
    return new TrackRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackRequest {
    return new TrackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackRequest {
    return new TrackRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TrackRequest | PlainMessage<TrackRequest> | undefined, b: TrackRequest | PlainMessage<TrackRequest> | undefined): boolean {
    return proto3.util.equals(TrackRequest, a, b);
  }
}

/**
 * @generated from message uxstack.sdk.v1.TrackResponse
 */
export class TrackResponse extends Message<TrackResponse> {
  /**
   * @generated from field: bool status = 1;
   */
  status = false;

  constructor(data?: PartialMessage<TrackResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "uxstack.sdk.v1.TrackResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackResponse {
    return new TrackResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackResponse {
    return new TrackResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackResponse {
    return new TrackResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TrackResponse | PlainMessage<TrackResponse> | undefined, b: TrackResponse | PlainMessage<TrackResponse> | undefined): boolean {
    return proto3.util.equals(TrackResponse, a, b);
  }
}

